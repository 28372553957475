import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"

const NotFoundPage = () => (
  <MainContainer>
    <HeadData
      title='404: Page Not found'
    />

  <div className='lg:min-h-screen bg-white md:pt-12 md:pb-24 px-4 py-12'>
    <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-4'>
      <StaticImage
        src="../../images/logo/command-access-logo-icon.png"
        width={500}
        quality={95}
        formats={["AUTO", "WEBP"]}
        alt="Command Access logo"
        className='w-64'
      />
      <h1 className='font-display text-5xl text-red-900 text-center tracking-wider leading-tight'>Oops! The requested page could not be found.</h1>

      <Link to='/'>
        <button className="bg-red-900 hover:bg-red-800 font-display text-white text-2xl uppercase font-bold mt-6 py-3 px-6 rounded tracking-widest shadow">
          Go To Home Page
        </button>
      </Link>
    </div>
  </div>
  </MainContainer>
)

export default withPrismicUnpublishedPreview(NotFoundPage)